import "core-js/stable";
import "regenerator-runtime/runtime";

import './routers/main';

import page from 'page';

document.addEventListener("DOMContentLoaded", function (event) {

    page();
});
