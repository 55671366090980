import superagent from 'superagent';

module.exports = (function () {

    let instance;
  
    function init() {
  
      let token = null;
  
      return {
  
        apiCall: (url, method='get') => {

            if(token){

                return superagent[method](url).set('pragma','no-cache').set('cache-control','no-cache').set('authorization',`bearer ${token}`);
            }else{

                return superagent[method](url).set('pragma','no-cache').set('cache-control','no-cache');
            }
        },
        getTemplate: (url) => {

            return new Promise((resolve, reject)=>{

                superagent.get(url).set('pragma','no-cache').set('cache-control','no-cache').send().end((err,res)=>{

                    if(err){

                        reject(err);
                    }else{

                        resolve(res.text);
                    }
                });
            });
        },
        setToken: (tkn)=>{

            token = tkn;
        }
      };
  
    };
  
    return {
        getInstance: () => {

            if ( !instance ) {

                instance = init();
            }

            return instance;
        }
    };
})().getInstance();