import '../pages';

import page from 'page';
import m from 'mustache';

import helper from '../modules/helper';
import request from '../modules/request';

export default (function(){

    page('/',()=>{

        if(helper.isAuthenticated()){

            page('/dashboard');
        }else{

            page('/login');
        }
    });

    page('*', () => {

		layout.resetLayout();
        request.getTemplate('/templates/default/404.html').then((template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			helper.reInitJS();
        }).catch((err)=>{

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message
            });
        });
    });

    return self;
})();
