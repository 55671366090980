import page from 'page';
import m from 'mustache';

import layout from '../../modules/layout';
import helper from '../../modules/helper';
import request from '../../modules/request';

export default (function () {

    page('/record_medical', ()=>{

        request.getTemplate('/templates/records/medical.html').then(async (template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			try{

				await layout.loadLayout('records');

				helper.reInitJS();
			}catch(err){

				helper.showError(err.message);
			}
        }).catch((err)=>{

			helper.showError(err.message);
        });
    });

    page('/record_criminal', ()=>{

        request.getTemplate('/templates/records/criminal.html').then(async (template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			try{

				await layout.loadLayout('records');

				helper.reInitJS();
			}catch(err){

				helper.showError(err.message);
			}
        }).catch((err)=>{

			helper.showError(err.message);
        });
    });

    page('/record_marital', ()=>{

        request.getTemplate('/templates/records/marital.html').then(async (template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			try{

				await layout.loadLayout('records');

				helper.reInitJS();
			}catch(err){

				helper.showError(err.message);
			}
        }).catch((err)=>{

			helper.showError(err.message);
        });
    });

    return self;
})();
