import m from 'mustache';

// import helper from '../modules/helper';
import request from '../modules/request';

export default {
	load:(active_tab = 'dashboard')=>{

		return new Promise((resolve,reject)=>{

			request.getTemplate('/templates/components/sidebar.html').then((template)=>{

				template = m.render(template, {
					isActive: ()=>{

						return (val, render) => {

							return render(val) === active_tab ? "active" : '';
						}
					}
				});

				resolve(template);
			}).catch((err)=>{

				reject(err);
			});
		});
	}
};
