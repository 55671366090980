module.exports = (function () {

    let instance;

    function init() {

        const _reinitJS = (path)=>{

            const target = _findScript(path);

			if(target){

				target.remove();
			}
            const js = document.createElement('script');
            js.src = path;
            document.body.append(js);
        };

        const _findScript = (src)=>{

            let allScripts = document.getElementsByTagName("script");
            let target;
            for(let i = 0, max = allScripts.length; i < max; i++){

                const check = allScripts[i].src.replace(src,'');
                const cropped = allScripts[i].src.replace(check,'');
                if (cropped === src){
                    target = allScripts[i];
                    break;
                }
            }

            return target;
        }

        let token = null;

      return {

        isAuthenticated: ()=>{

            return (token !== null);
        },
        setToken: (tkn)=>{

            localStorage.setItem('tokenAuth',tkn);
            token = tkn;
        },
        unsetToken: ()=>{

            token = null;
            localStorage.removeItem('tokenAuth');
        },
        reInitJS: ()=>{

            const scripts = [
                // "/vendor/@popperjs/core/dist/umd/popper.min.js",
                // "/vendor/bootstrap/dist/js/bootstrap.min.js",
                // "/vendor/onscreen/dist/on-screen.umd.min.js",
                // "/vendor/nouislider/dist/nouislider.min.js",
                // "/vendor/smooth-scroll/dist/smooth-scroll.polyfills.min.js",
                // "/vendor/chartist/dist/chartist.min.js",
                // "/vendor/chartist-plugin-tooltips/dist/chartist-plugin-tooltip.min.js",
                // "/vendor/vanillajs-datepicker/dist/js/datepicker.min.js",
                // "/vendor/sweetalert2/dist/sweetalert2.all.min.js",
                // "/vendor/vanillajs-datepicker/dist/js/datepicker.min.js",
                // "/vendor/notyf/notyf.min.js",
                // "/vendor/simplebar/dist/simplebar.min.js",
                "/assets/js/volt.js"
            ];

            for(let i=0;i<scripts.length;i++){

                _reinitJS(scripts[i]);
            }
        },
		showError:(message,title='Ooppss...')=>{

			Swal.fire({
				icon: 'error',
				title: title,
				text: message
			});
		}
      };

    };

    return {
        getInstance: () => {

            if ( !instance ) {

                instance = init();
            }

            return instance;
        }
    };
})().getInstance();
