import page from 'page';
import m from 'mustache';

import layout from '../../modules/layout';
import helper from '../../modules/helper';
import request from '../../modules/request';

export default (function () {

    page('/profile', ()=>{

        request.getTemplate('/templates/accounts/profile.html').then(async (template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			try{

				await layout.loadLayout('dashboard');

				helper.reInitJS();
			}catch(err){

				helper.showError(err.message);
			}
        }).catch((err)=>{

			helper.showError(err.message);
        });
    });

    return self;
})();
