import m from 'mustache';

// import helper from '../modules/helper';
import request from '../modules/request';

export default {
	load:()=>{

		return new Promise((resolve,reject)=>{

			request.getTemplate('/templates/components/footer.html').then((template)=>{

				template = m.render(template, {
					year: new Date().getFullYear()
				});

				resolve(template);
			}).catch((err)=>{

				reject(err);
			});
		});
	}
};
