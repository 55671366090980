import page from 'page';
import m from 'mustache';

import layout from '../../modules/layout';
import helper from '../../modules/helper';
import request from '../../modules/request';

export default (function () {

    page('/assets_land', ()=>{

        request.getTemplate('/templates/assets/land.html').then(async (template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			try{

				await layout.loadLayout('assets');

				helper.reInitJS();
			}catch(err){

				helper.showError(err.message);
			}
        }).catch((err)=>{

			helper.showError(err.message);
        });
    });
    
    page('/assets_stock', ()=>{

        request.getTemplate('/templates/assets/stock.html').then(async (template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			try{

				await layout.loadLayout('assets');

				helper.reInitJS();
			}catch(err){

				helper.showError(err.message);
			}
        }).catch((err)=>{

			helper.showError(err.message);
        });
    });

    return self;
})();
