import sidebar from '../components/sidebar';
import navbar from '../components/navbar';
import footer from '../components/footer';

export default {
	loadLayout:(tab='dashboard')=>{

		return new Promise(async (resolve,reject)=>{

			try{

				const sdbar = await sidebar.load(tab);
				const nvbar = await navbar.load();
				const ftbar = await footer.load();

				const sdbr = document.createElement('div');
				sdbr.innerHTML = sdbar;
				const nvbr = document.createElement('div');
				nvbr.innerHTML = nvbar;
				const fotbar = document.createElement('div');
				fotbar.innerHTML = ftbar;

				const main_content = document.getElementById('main-content');

				main_content.parentNode.insertBefore(sdbr.childNodes.item(0),main_content);
				main_content.parentNode.insertBefore(sdbr.childNodes.item(1),main_content);
				main_content.classList.add('content');
				document.getElementById('main-content').prepend(nvbr.firstChild);
				document.getElementById('main-content').append(fotbar);

				resolve();
			}catch(err){

				reject(err);
			}
		});
	},
	resetLayout:()=>{

		let allNav = document.getElementsByTagName("nav");

		while(allNav[0]) {
			allNav[0].parentNode.removeChild(allNav[0]);
		}

		document.getElementById('main-content').classList.remove('content');

		document.getElementById('main-content').innerHTML = '';
	}
};
