import './auth';
import './dashboard';
import './accounts';
import './licenses';
import './assets';
import './credentials';
import './debts';
import './polls';
import './entities';
import './records';
import './wallets';
import './statements';
import './regions';
import './users';
import './settings';