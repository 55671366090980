import page from 'page';
import m from 'mustache';

import helper from '../../modules/helper';
import request from '../../modules/request';
import layout from '../../modules/layout';

export default (function () {

    page('/login', ()=>{

		layout.resetLayout();
        request.getTemplate('/templates/auth/login.html').then((template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

			helper.reInitJS();
        }).catch((err)=>{

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message
            });
        });
    });
    
    page('/register', ()=>{

		layout.resetLayout();
        request.getTemplate('/templates/auth/register.html').then((template)=>{

            template = m.render(template, {});

            document.getElementById('main-content').innerHTML = template;

            // document.getElementById('frm').onsubmit = (e)=>{

            //     e.preventDefault();
            // };

			helper.reInitJS();
        }).catch((err)=>{

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message
            });
        });
    });

    return self;
})();
